$(document).ready(function() {

  $('.truncator').each( function () {

    var h = $(this).outerHeight();
    if ( h > 500 ) {
      $(this).addClass('truncate');
    } else {
      $(this).removeClass('truncate');
    }
  });
  
  $('.truncator').click( function () {
    $(this).toggleClass('expanded');
  });

});