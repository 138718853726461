$(document).ready(function() {
  
    // initialise project-widget

    $('.project-widget__slider').slick({
        asNavFor: '.project-widget__slider-nav',
        speed: 750,
        arrows: false,
        accessibility: false
    });

    $('.project-widget__slider-nav').slick({
        asNavFor: '.project-widget__slider',
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        dots: false,
        arrows: false,
        accessibility: false,

        responsive: [
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2
            }
          }
        ]
    });


    // when a nav slide is clicked, reset to the first image (strategy)
    $('.project-widget__slider-nav__thumb').click( function () {
      selectImage('strategy');
    })

    // reset images on swipe 
    $('.project-widget__slider').on('swipe', function (event, slick, direction){
      console.log(direction);
      selectImage('strategy');
    });


    // handle images

    var currentImage = null;
    selectImage('strategy');

    function selectImage (image) {
        // console.log('selected image: ' + image);

        if (currentImage != image) {
            //reset
            $('.project-widget__image').fadeOut();
            $('.project-widget__sections__button').removeClass('active');
            
            // show selected
            $('.img-'+image).fadeIn();
            $('.btn-'+image).addClass('active');

            currentImage = image;
        }        
    };

    
    $('.btn-strategy').click( function () {
        selectImage('strategy');
    });
    $('.btn-branding').click( function () {
        selectImage('branding');
    });
    $('.btn-activation').click( function () {
        selectImage('activation');
    });
    $('.btn-results').click( function () {
        selectImage('results');
    });
  
});