$(document).ready(function() { 

    $('.carousel').slick({
      autoplay: true,
      dots: true,
      arrows: false,
      dotsClass: 'carousel__dots',
      accessibility: false
    });
  
});
  