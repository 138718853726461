$(document).ready(function() { 

    $('.carousel-basic').slick({
      autoplay: true,
      dots: false,
      arrows: false,
      accessibility: false
    });
  
});
