$(document).ready(function() {

  $('#search-toggle').click(function() {
    $(this).toggleClass('active');
    $('#search').toggleClass('open');
    $('#homepage').toggleClass('scroll-locked');
  });

  $('#search-close').click(function() {
    $('#search').toggleClass('open');
    $('#homepage').toggleClass('scroll-locked');
  });

});