$(document).ready(function() {

  $('#nav-toggle').click(function() {
    $(this).toggleClass('active');
    $('#overlay').toggleClass('open');
    $('#homepage').toggleClass('scroll-locked');
    $('.main-menu__sub').removeClass('visible');
  });

  $('.next').click(function() {
    $(this).siblings('.main-menu__sub').toggleClass('visible');
  });

  $('.back').click(function() {
    $(this).parent().parent('.main-menu__sub').toggleClass('visible');
  });

});