$(document).ready(function() { 

  // parallax handler
  var parallaxController = new ScrollMagic.Controller();
  var $mb = $('.main-banner');

  var slideParallaxScene = new ScrollMagic.Scene({
      triggerElement: $('.parallax'),
      triggerHook: 1,
      duration: "100%"
  })
  .setTween(TweenMax.to($mb, 1, {y: '65%', ease:Power0.easeNone}))
  .addTo(parallaxController);


  // opacity handler
  var opacityController = new ScrollMagic.Controller();
  var $ca = $('.carousel-homepage');

  var opacityScene = new ScrollMagic.Scene({
    triggerElement: $('.parallax'),
    triggerHook: 1,
    duration: "100%"
  })
  .setTween(TweenMax.to($ca, 1, {opacity: 0, ease:Power0.easeNone}))
  .addTo(opacityController);


});