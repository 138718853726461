$(document).ready(function() { 

    $('.logo-carousel').slick({
      fade: false,
      autoplay: true,
      dots: false,
      arrows: false,
      dotsClass: 'carousel__dots',
      accessibility: false,
      slidesToShow: 5
    });

    // $('.logo-carousel').on('setPosition', function() {
    //   $(this).find('.slick-slide').height('auto');
    //   var slickTrack = $(this).find('.slick-track');
    //   var slickTrackHeight = $(slickTrack).height();
    //   $(this).find('.logo-carousel__slide').css('height', slickTrackHeight + 'px');
    // });
  
});
  