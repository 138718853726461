$(document).ready(function() { 

    $('.quote-carousel').slick({
      fade: true,
      autoplay: true,
      dots: true,
      arrows: false,
      dotsClass: 'carousel__dots',
      accessibility: false
    });
  
});
  