$(document).ready(function() {
  
  // handle images
  var currentImage = null;
  var currentSlide = 0;
  var playing = true;
  var slides = ['brand', 'marketing', 'packaging', 'digital', 'experiential', 'events'];
  selectImage(slides[currentSlide]);
  

  // auto-play
  window.setInterval(function(){
    
    if (playing) {
      if ( currentSlide < slides.length -1 ) {
        currentSlide += 1;
        selectImage(slides[currentSlide]);
      } else {
        currentSlide = 0;
        selectImage(slides[currentSlide]);
      }
    };

  }, 2000);


  // detect mouse over to stop auto-playing
  $('.cat-widget')
    .mouseenter( 
      function(){
        playing = false;
      } 
    )
    .mouseleave( 
      function(){
        playing = true;
      } 
    );


  //select image
  function selectImage (image) {

      if (currentImage != image) {
          //reset
          $('.cat-widget__image').fadeOut();
          $('.cat-widget__buttons__link').removeClass('active');
          
          // show selected
          $('.cat-img-'+image).fadeIn();
          $('.cat-btn-'+image).addClass('active');

          currentImage = image;
      }        
  };

  // button handlers
  $('.cat-btn-brand').click( function () {
    selectImage('brand');
  });
  $('.cat-btn-marketing').click( function () {
    selectImage('marketing');
  });
  $('.cat-btn-packaging').click( function () {
    selectImage('packaging');
  });
  $('.cat-btn-digital').click( function () {
    selectImage('digital');
  });
  $('.cat-btn-experiential').click( function () {
    selectImage('experiential');
  });
  $('.cat-btn-events').click( function () {
    selectImage('events');
  });


  $('.cat-btn-brand').hover( function () {
    selectImage('brand');
  });
  $('.cat-btn-marketing').hover( function () {
    selectImage('marketing');
  });
  $('.cat-btn-packaging').hover( function () {
    selectImage('packaging');
  });
  $('.cat-btn-digital').hover( function () {
    selectImage('digital');
  });
  $('.cat-btn-experiential').hover( function () {
    selectImage('experiential');
  });
  $('.cat-btn-events').hover( function () {
    selectImage('events');
  });

});