$(document).ready(function() { 

  var controller = new ScrollMagic.Controller();
	  var globalTrigger = 0.8;

	  $('.sm-grow-left').each(function() {
	    var bars = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: globalTrigger,
	    })
	    .setClassToggle(this, 'sm-grow-left--end')
	    .addTo(controller);
	  });


	  $('.sm-fade-slide-up').each(function() {
	    var fadeAndSlide = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: globalTrigger,
	    })
	    .setClassToggle(this, 'sm-fade-slide-up--end')
	    .addTo(controller);
	  });


	  $('.sm-fade-slide-up-immediate').each(function() {
	    var fadeAndSlide = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: 1,
	    })
	    .setClassToggle(this, 'sm-fade-slide-up-immediate--end')
	    .addTo(controller);
	  });


	  $('.sm-fade-slide-left').each(function() {
	    var fadeAndSlide = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: globalTrigger,
	    })
	    .setClassToggle(this, 'sm-fade-slide-left--end')
	    .addTo(controller);
	  });


	  $('.sm-fade').each(function() {
	    var fadeAndSlide = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: globalTrigger,
	    })
	    .setClassToggle(this, 'sm-fade--end')
	    .addTo(controller);
	  });


	  $('.sm-fade-immediate').each(function() {
	    var fadeAndSlide = new ScrollMagic.Scene({
	      triggerElement: this,
	      triggerHook: 1,
	    })
	    .setClassToggle(this, 'sm-fade-immediate--end')
	    .addTo(controller);
	  });


});